<template>
  <TableIndex
    :title="title"
    :resource-name="resourceName"
    :singular-name="singularName"
  />
</template>

<script>
export default {
  data() {
    return {
      title: 'Roles',
      resourceName: 'roles',
      singularName: 'role',
    }
  },
}
</script>
