var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableIndex', {
    attrs: {
      "title": _vm.title,
      "resource-name": _vm.resourceName,
      "singular-name": _vm.singularName
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }